<template>
<b-card>
    <code>{{ orderData }}</code>
</b-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            orderData: 'order/order'
        })
    }
}
</script>
